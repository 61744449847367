import { ReactComponent as LinkedIcon } from '../assets/footerLinkedinIcon.svg';


const Footer = () => {

  return (
    <div className="flex flex-col lg:flex-row justify-between lg:h-[140px] bg-brand-primary text-white w-screen">
      <div className="flex flex-col justify-center lg:items-start p-12 text-center">
        <p className='font-semibold text-white mb-1'>Legal</p>
        <a href="/terms" className='text-white underline mb-1'>Terms of use</a>
        <a href='/privacy-policy' className='text-white underline'>Privacy policy</a>
      </div>
      <div className="flex flex-col justify-center items-center">
        <p className='text-white'>@ 2025 uNetwrk</p>
        <p className='text-white'>All rights reserved.</p>
      </div>
      <div className="flex flex-col justify-center items-center p-12">
        <a className='text-white mb-2' href='mailto:support@unetwrk.com' target="_blank">Contact us</a>
        <a className='text-white' href='https://www.linkedin.com/company/unetwrk' target="_blank" rel="noreferrer">
        <LinkedIcon className="h-6 w-6 text-white"/>
        </a>
      </div>            
    </div>
  )

}

export default Footer;