import logo from '../assets/logo_circle.svg';
import { useHistory } from 'react-router-dom';
import { BUTTON_CLASS_ORANGE, BUTTON_CLASS_WHITE } from '../constants';

const MobileLandingHeader = () => {

  const history = useHistory();

  const handleGoPage = (address) => {
    history.push(address);
  }

  return (

    <div className="flex flex-row items-center justify-between p-2 px-5 py-4 w-screen border-solid border-b-4 border-brand-primary sticky top-0 left-0 bg-white z-[5000]">
      <img className='cursor-pointer' onClick={e => handleGoPage('/')} src={logo} alt="Unetwrk Logo" />
      <div className='flex flex-row items-center justify-center'>
        <button onClick={e => handleGoPage('/login')} className={BUTTON_CLASS_WHITE}>Log in</button>
        <div className='w-[130px] flex flex-row items-center justify-center h-[10px] + ml-4'>
          <button onClick={e => handleGoPage('/signup')} className={BUTTON_CLASS_ORANGE}>
            <p className='font-[600]'>Get started</p>
          </button>
        </div>
      </div>
    </div>

  )

}

export default MobileLandingHeader;