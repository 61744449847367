import shot1 from '../assets/landing/lshot1.png';
import shot2 from '../assets/landing/contact_board_shot.jpg';
import coldVersusNetwork from '../assets/landing/cold_versus_net.png';
import coldVersusNetworkVertical from '../assets/landing/cold_versus_net_vertical.png';
import contactShot1 from '../assets/landing/contact_shot_1.png';
import contactShot2 from '../assets/landing/contact_shot_2.png';
import contactShot3 from '../assets/landing/contact_shot_3.png';
import { useHistory } from 'react-router-dom';
import LandingHeader from './LandingHeader';
import Footer from './Footer';
import { BIG_BODY, BODY, BODY_BOLD, BUTTON_CLASS_ORANGE, GIANT_TEXT, HEADER_1, HEADER_2, HEADER_3, SUBTITLE, SUBTITLE_BOLD } from '../constants';
import MobileLandingHeader from './MobileLandingHeader';
import launch from '../assets/landing/launch.svg';
import lady from '../assets/landing/lady.svg';
import cabinet from '../assets/landing/cabinet.svg';



const LandingPage = () => {

  const history = useHistory();

  const handleGoSignup = (e) => {
    history.push('/signup');
  }

  const mobileClient = window.innerWidth < 1024


  return (

    <div className="flex flex-col items-center justify-center px-4 lg:px-[100px]">
      { mobileClient ? <MobileLandingHeader/> : <LandingHeader/> }
      <div className='flex flex-col items-center w-full lg:w-[1144px]'>

        <div className='flex flex-col lg:flex-row items-center justify-between w-full my-8 lg:my-[64px] lg:mb-[128px]'>
          <div className="flex flex-col items-start justify-center">
            <p className={ mobileClient ? HEADER_2 + 'mb-2 text-brand-primary font-advent' : HEADER_1 + 'mb-2 text-brand-primary font-advent'}>Build connections.</p>
            <p className={mobileClient ? HEADER_2 + 'mb-4 text-brand-primary font-advent' : HEADER_1 + 'mb-2 text-brand-primary font-advent '}>Launch your career.</p>
            <p className={SUBTITLE + 'mb-4 leading-tight lg:w-[448px]'}>Build professional relationships that support your career growth.</p>
            <p className='mb-8'>Manage your network with ease and watch your career take off.</p>
            <div className='w-full flex flex-row lg:justify-start justify-center '>
              <div className='h-[40px] lg:w-[300px] flex flex-row items-center justify-start'>
                <button onClick={handleGoSignup} className={BUTTON_CLASS_ORANGE + 'flex flex-row justify-center items-center'}>
                  <p className="font-[600]">Start networking with ease today</p>
                </button>
              </div>
            </div>
          </div>
          <img src={launch} className='lg:h-[467px] w-auto my-20 lg:my-0' />
        </div>

        <div className='flex flex-col items-center justify-center w-full lg:mt-24'>
          <p className={mobileClient ? HEADER_3 + 'w-full  text-center' : HEADER_2 + ' mb-8  w-full text-center'}>Networking works</p>
          <div className='flex flex-col lg:flex-row justify-center items-center mt-6 lg:mt-12 mb-6 lg:mb-24'>
            <div className='bg-bp10 w-[363px] rounded-xl drop-shadow-xl lg:mr-6 p-4 mb-6'>
              <p className={SUBTITLE + 'leading-tight'}>"I didn't find my job through any of the job boards, I found it through networking."</p>
            </div>
            <div className='bg-bp10 w-[363px] rounded-xl drop-shadow-xl lg:mr-6 p-4 mb-6'>
              <p className={SUBTITLE + 'leading-tight'}>“I got my job through a networking connection.”</p>
            </div>
            <div className='bg-bp10 w-[363px] rounded-xl drop-shadow-xl p-4 mb-6'>
              <p className={SUBTITLE + 'leading-tight'}>“I focused on building relationships networking more than on applications.”</p>
            </div>
          </div>
          <div>
            <img src={mobileClient ? coldVersusNetworkVertical : coldVersusNetwork} alt="Cold applying versus networking" />
          </div>
        </div>
        <div className='flex flex-col-reverse lg:flex-row items-center justify-center w-full mb-[128px] m-12 lg:mt-28'>
          <div className='flex flex-col items-center justify-center lg:items-start w-full lg:w-[650px]'>
            <div className='flex flex-row justify-start items-center mb-12'>
              <p className={mobileClient ? HEADER_1 + 'text-brand-primary mr-4' : GIANT_TEXT + 'text-brand-primary mr-4'}>88%</p>
              <p className={mobileClient ? SUBTITLE  : HEADER_3 + 'leading-tight'}>of employers rate referrals as the best ROI for sourcing talent.</p>
            </div>
            <div className='flex flex-row justify-start items-center '>
              <p className={mobileClient ? HEADER_1 + 'text-brand-primary mr-4' : GIANT_TEXT + 'text-brand-primary mr-4'}>54%</p>
              <p className={mobileClient ? SUBTITLE  : HEADER_3 + 'w-[438px] leading-tight'}>of hires originated from referrals in 2022.</p>
            </div>
          </div>
        </div>
        {/* <div className='w-full lg:w-[650px] flex justify-center lg:justify-start'> */}
        <p className={mobileClient ? HEADER_3 + 'mb-8 w-full text-center' : HEADER_2 + 'mb-8 w-full text-center'}>Your network is your net worth.</p>
        {/* </div> */}
        <div className='flex flex-col lg:flex-row items-center justify-between w-full mb-8 lg:mb-16'>
          <div className='flex flex-col lg:w-[450px]'>
            <p className={mobileClient ? SUBTITLE_BOLD + 'mb-4 text-center' : HEADER_3 + 'mb-4'}>But, networking is a pain.</p>
            <p className={mobileClient ? BIG_BODY + 'mb-2' : SUBTITLE + 'mb-2'}>You create spreadsheets or Notion tables.</p>
            <p className={mobileClient ? BIG_BODY + 'mb-2' : SUBTITLE + 'mb-2'}>You copy and past connections’ info.</p>
            <p className={mobileClient ? BIG_BODY + 'mb-2' : SUBTITLE + 'mb-2'}>You miss opportunities because you lose track and forget to follow up.</p>
            <p className={mobileClient ? BIG_BODY + 'mb-2' : SUBTITLE + 'mb-2'}>You struggle to write a follow-up message.</p>
            <p className={mobileClient ? BIG_BODY + 'mb-2' : SUBTITLE + 'mb-2'}>It’s time consuming & frustrating.</p>
          </div>
          <img src={lady} className='lg:h-[488px] w-auto my-8'/>
        </div>
        <div className='flex flex-col-reverse lg:flex-row-reverse items-center justify-between w-full lg:mb-[128px] mb-[64px]'>
          <img src={cabinet} className='lg:h-[406px] w-auto my-8'/>
          <div className='flex flex-col lg:w-[450px]'>
            <p className={mobileClient ? SUBTITLE_BOLD + 'mb-4 text-center' : HEADER_3 + 'mb-4'}>uNetwrk makes it easy.</p>
            <p className={mobileClient ? BIG_BODY + 'mb-2' : SUBTITLE + 'mb-2'}>Use our Chrome extension to save contacts from LinkedIn.</p>
            <p className={mobileClient ? BIG_BODY + 'mb-2' : SUBTITLE + 'mb-2'}>Your connections organized in one place.</p>
            <p className={mobileClient ? BIG_BODY + 'mb-2' : SUBTITLE + 'mb-2'}>Keep track of your communication.</p>
            <p className={mobileClient ? BIG_BODY + 'mb-2' : SUBTITLE + 'mb-2'}>Get reminders to follow up.</p>
            <p className={mobileClient ? BIG_BODY + 'mb-2' : SUBTITLE + 'mb-2'}>Generate a follow-up message with AI.</p>
          </div>
        </div>
        <p className={mobileClient ? HEADER_3 + 'w-full text-center' : HEADER_2 + 'mb-2 w-full text-center'}>Save & arrange</p>
        <p className={mobileClient ? SUBTITLE_BOLD + 'w-full text-center mt-5 mb-10' : SUBTITLE + 'mb-12 w-full text-center'}>Autosave contact’s info from LinkedIn. Easy organization.</p>
        <div className='flex flex-col-reverse lg:flex-row justify-between items-center lg:mb-20 mb-8 w-full lg:w-[1144px] '>
          <div className='flex flex-col lg:w-1/3 my-6' >
            {!mobileClient && <p className={HEADER_3 + 'mb-4'}>Chrome extension</p>}
            <p className={BIG_BODY + 'leading-tight mt-1'}>Use the Chrome extension to add contacts from LinkedIn. The information is automatically saved to your contact card board.</p>
          </div>
          <div className='flex items-center justify-center w-full aspect-[16/9] lg:w-[680px] lg:h-[400px] bg-gradient-to-b from-[#00B395] to-[#004D40] rounded-lg drop-shadow-lg'>
            <img className='w-[calc(100%-30px)] h-[calc(100%-30px)]' src={shot1} alt='shot1' />
          </div>
          {mobileClient && <p className={SUBTITLE_BOLD + 'text-center mb-4'}>Chrome extension</p>}
        </div>
        <div className='flex flex-col-reverse lg:flex-row flex-row justify-between items-center lg:mb-20 mb-8 w-full lg:w-[1144px]  '>
          <div className='flex flex-col lg:w-1/3 my-6' >
          {!mobileClient && <p className={HEADER_3 + 'mb-4'}>Contact card board</p>}
            <p className={BIG_BODY + 'leading-tight mt-1'}>Organize contact cards in columns. Use the preset column headers or edit them to your preferences. </p>
          </div>
          <div className='flex items-center justify-center w-full aspect-[16/9] lg:w-[680px] lg:h-[400px] bg-gradient-to-b from-[#00B395] to-[#004D40] rounded-lg drop-shadow-lg'>
            <img className='w-[calc(100%-30px)] h-[calc(100%-30px)]' src={shot2} alt='shot2' />
          </div>
          {mobileClient && <p className={SUBTITLE_BOLD + 'text-center mb-4'}>Contact card board</p>}
        </div>
        <div className='w-full lg:mt-16'>
          <p className={mobileClient ? HEADER_3 + 'w-full text-center' : HEADER_2 + 'mb-1 w-full text-center'}>Track & act</p>
          <p className={mobileClient ? SUBTITLE + 'w-full text-center mt-2 mb-6' : SUBTITLE + 'mb-[64px] w-full text-center'}>All your networking details in one place. Smarter follow-ups made easy.</p>
          <div className='flex flex-col items-center justify-center'>
            <div className='flex flex-col lg:flex-row-reverse items-center justify-between mb-6 lg:mb-[128px] w-full'>
              <div className='flex flex-col w-[332px]'>
                <p className={mobileClient ? SUBTITLE_BOLD + 'mb-2 text-center' : HEADER_3 + 'mb-4'}>Contact information</p>
                {!mobileClient && <p className={BIG_BODY + 'w-[332px]'}>All your contact’s key info in one spot, plus a link to their LinkedIn profile.</p>}
              </div>
              <img className='w-[641px] border border-[20px] [border-image:linear-gradient(to_bottom,#00B395,#004D40)_30]' src={contactShot1} alt='shot1' />
              {mobileClient && <p className={BODY + 'mt-2 w-[332px]'}>All your contact’s key info in one spot, plus a link to their LinkedIn profile.</p>}

            </div>
            <div className='flex flex-col lg:flex-row items-center justify-between mb-6 lg:mb-[128px] w-full'>
              <div className='flex flex-col w-[332px]'>
                <p className={mobileClient ? SUBTITLE_BOLD + 'mb-2 text-center' : HEADER_3 + 'mb-4'}>Interaction notes</p>
                {!mobileClient && <p className={BIG_BODY + 'w-[332px]'}>Keep good interaction notes and set a date to follow up. You’ll get a notification on that date.</p>}
              </div>
              <img className='w-[641px] border border-[20px] [border-image:linear-gradient(to_bottom,#00B395,#004D40)_30]' src={contactShot2} alt='shot1' />
              {mobileClient && <p className={BODY + 'mt-2 w-[332px]'}>Keep good interaction notes and set a date to follow up. You’ll get a notification on that date.</p>}
            </div>
            <div className='flex flex-col lg:flex-row-reverse items-center justify-between w-full'>
              <div className='flex flex-col w-[332px]'>
                <p className={mobileClient ? SUBTITLE_BOLD + 'mb-2 text-center' : HEADER_3 + 'mb-4'}>AI-generated follow-up</p>
                {!mobileClient && <p className={BIG_BODY + 'w-[332px]'}>Let AI draft a follow-up message. Email it or copy to send on LinkedIn.</p>}
              </div>
              <img className='w-[641px] border border-[20px] [border-image:linear-gradient(to_bottom,#00B395,#004D40)_30]' src={contactShot3} alt='shot1' />
              {mobileClient && <p className={BODY + 'mt-2 w-[332px]'}>Let AI draft a follow-up message. Email it or copy to send on LinkedIn.</p>}

            </div>
          </div>
        </div>
        <div className='flex flex-col items-center justify-center pt-0 lg:w-[592px] text-center my-[128px]'>
          <p className={mobileClient ? HEADER_3 + 'mb-4' : HEADER_2 + 'mb-2 w-[590px]'}>Network your way to success!</p>
          <p className={mobileClient ? SUBTITLE_BOLD : HEADER_3 + 'mb-2'}>Make networking easier and more effective.</p>
          <div className='flex flex-col lg:flex-row justify-center items-center w-full mt-4'>
            <div className='flex flex-col bg-pale-green p-3 w-[216px] rounded lg:mr-12 mb-6 lg:mb-0'>
              <div className='flex flex-col items-center justify-center bg-white mb-3 rounded drop-shadow p-3'>
                <p className={SUBTITLE_BOLD}>Free</p>
                <p className={BODY_BOLD}>$0/month</p>
              </div>
              <div className='flex flex-col items-center justify-center bg-white rounded drop-shadow p-3'>
                <p><span className={BODY}>10</span> contact cards</p>
                <p>Chrome extension</p>
              </div>
            </div>
            <div className='flex flex-col bg-pale-green p-3 w-[216px] rounded'>
              <div className='flex flex-col items-center justify-center bg-white mb-3 rounded drop-shadow p-3'>
                <p className={SUBTITLE_BOLD}>Premium</p>
                <p className={BODY_BOLD}>$10/month</p>
              </div>
              <div className='flex flex-col items-center justify-center bg-white rounded drop-shadow p-3'>
                <p><span className={BODY_BOLD}>Unlimited</span> contact cards</p>
                <p>Chrome extension</p>
              </div>
            </div>
          </div>
          <div className='h-[40px] w-full flex flex-row items-center justify-center mt-6'>
            <button className={BUTTON_CLASS_ORANGE + 'mt-6 lg:w-[483px]'} onClick={handleGoSignup}>
              <p className='font-[600]'>
                Start building your network today
              </p>
            </button>
          </div>
        </div>
      </div>
      <Footer/>
    </div>

  )

}

export default LandingPage;