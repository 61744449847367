import logo from '../assets/newWordmark.png';
import { useHistory } from 'react-router-dom';
import { BUTTON_CLASS_ORANGE, BUTTON_CLASS_WHITE } from '../constants';

const LandingHeader = () => {

  const history = useHistory();

  const handleGoPage = (address) => {
    history.push(address);
  }

  return (

    <div className="flex flex-row items-center justify-between px-8 w-screen border-solid border-b-4 border-brand-primary py-4 sticky top-0 left-0 bg-white z-[5000] ">
      <img className='cursor-pointer h-[40px]' onClick={e => handleGoPage('/')} src={logo} alt="Unetwrk Logo" />
      <div className='flex flex-row items-center justify-center'>
        <button onClick={e => handleGoPage('/login')} className={BUTTON_CLASS_WHITE}>Log in</button>
        <div className='w-[180px] flex flex-row items-center justify-center h-[10px]'>
          <button onClick={e => handleGoPage('/signup')} className={BUTTON_CLASS_ORANGE}>
            <p className='font-[600]'>Get started</p>
          </button>
        </div>
      </div>
    </div>

  )

}

export default LandingHeader;